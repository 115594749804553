
<template>
<form autocomplete="off" class="">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row g-3">
    <div class="col-lg-6">
      <label> Select medicine type </label>
      <multiselect 
      mode="single"
      searchable
      valueProp="id"
      trackBy="title" label="title"
      @change="medicineTypeChange($event)"
      :options="medicineTypes" 
        placeholder="--select --"></multiselect>
    </div>
    <div class="col-lg-6">
      <label> Medicine </label>
      <multiselect 
        mode="single"
        searchable
        valueProp="id"
        trackBy="name" label="name"
        :options="medicineOptions" 
        v-model="data.medicine_id" placeholder="--select medicine --">
        <template v-slot:option="{ option }">
          <div class="">
            <h6 class="mb-0">{{ option.name }} - {{ option.type_title }}</h6>
            <span>{{ option.description }}</span>
          </div>
        </template>
      </multiselect>
    </div> 
    <div v-if="selected" class="col-12">
      <div class="alert mb-0" :class="invalidQuantity ? 'alert-danger' :'alert-primary'">
        <p class="mb-0"><strong>Medicine: {{ selected.name }} - {{ selected.type_title }}</strong></p>
        <p class="mb-2">Description: {{ selected.description }}</p>
        <h6 class="mb-0">Available Quantity: {{$filters.number_format(selected.available_quantity)}}</h6>
      </div>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="quantity">Quantity <span class="text-lowercase" v-if="selected">(per {{ selected.minimum_unit }})</span></label>
      <input id="quantity" autocomplete="false" type="number" class="form-control" v-model="data.quantity"
      placeholder="Quantity" name="quantity" :class="{ 'is-invalid': v$.data.quantity.$error }"/>
      <div v-for="(item, index) in v$.data.quantity.$errors" :key="index"
        class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="dosage">Dosage</label>
      <input id="dosage" autocomplete="false" type="dosage" class="form-control" v-model="data.dosage"
      placeholder="e.g 400 mg" name="dosage"/>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="frequency">Frequency</label>
      <input id="frequency" autocomplete="false" type="frequency" class="form-control" v-model="data.frequency"
      placeholder="e.g Three times a day" name="frequency"/>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="duration">Duration</label>
      <input id="duration" autocomplete="false" type="duration" class="form-control" v-model="data.duration"
      placeholder="e.g 5 days" name="duration"/>
    </div>
    <div class="col-12">
      <label class="form-label">Instructions</label>
      <textarea v-model="data.instructions" class="form-control"
      rows="2" placeholder="Write instructions" name="instructions"></textarea>
    </div> 
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="d-flex">
        <b-button variant="primary"  @click.prevent="confirm()" type="button"> Submit </b-button>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { required, email, maxValue, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Multiselect,
    IsLoading,
  },
  props:{
    medicalHistoryId:{
      type: String,
      default: null 
    },
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isLoading: true,
      data: {  
        medical_history_id: this.medicalHistoryId,
        medicine_id: null,
        quantity: null,
        dosage: "",
        frequency: "",
        duration: "",
        instructions: ""
      },
      mirrorData: null,
      medicineTypes: [],
      medicineOptions:[]
    }
  },
  validations() {
    return {
      data:{
        medicine_id: {
          required: helpers.withMessage("Medicine is required", required),
        },
        quantity: {
          required: helpers.withMessage("Quantity is required", required),
          maxValue: maxValue(this.availableQuantity)
        },
      }
    }
  },
  computed:{
    selected(){
      return this.medicineOptions.find(i => i.id == this.data.medicine_id)
    },
    availableQuantity(){
      return this.selected ? this.selected.available_quantity : 0
    },
    invalidQuantity(){
      if(!this.selected || !this.data.quantity) return false;
      return parseInt(this.availableQuantity) < parseInt(this.data.quantity)
    }
  },
  methods: {
    confirm(){
      this.v$.$validate().then(result =>{
        if (!result) return;
        this.confirmAction({
          text: this.warningTemplate(),
        }).then((result) => {
          if (result.value) { this.create(); }
        });
      })
    },
    create() {
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.data))
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post('/prescribed-drugs/create', formData)
      .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.$emit('addItem', response.data.data)
            this.resetForm();
          }
      })
    },
    warningTemplate(){
      return ` <p>You are about to prescribe:</p>
            <h6>${this.data.quantity}-${this.selected.minimum_unit} of ${this.selected.name}</h6>
            <div>
              <span><strong>Available quantity:</strong></span>
              <span>${this.$filters.number_format(this.selected.available_quantity)}</span>
            </div>
            <div>
              <span><strong>Prescribed quantity:</strong></span>
              <span> - ${this.$filters.number_format(this.data.quantity)}</span>
            </div>
            <div>
              <span><strong>Remaining quantity:</strong></span>
              <span>${this.$filters.number_format(parseInt(this.selected.available_quantity) - parseInt(this.data.quantity))}</span>
            </div>
            <p class='mt-2'><i>Please ensure all details are accurate.<i></p>`
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/prescribed-drugs/${this.editItem.id}/update`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$emit('updatedItem', response.data.data)
              this.resetForm()
            }
          })
      })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
    getOptionData(){
      this.isLoading = true;
      this.$http.get(`/medicine-types`)
        .then((response) => {
        this.isLoading = false;
        if(response.data.success){
          this.medicineTypes = response.data.data
        }
      })
    },
    medicineTypeChange(type_id){
      this.medicineOptions = []
      this.data.medicine_id = null
      this.isLoading = true;
      this.$http.get(`/medicines?medicine_type_id=${type_id}`)
        .then((response) => {
        this.isLoading = false;
        if(response.data.success){
          this.medicineOptions = response.data.data
        }
      })
    }
  },
  created(){
    this.getOptionData()
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
    
<style scoped>
.bg-error-danger {
    --bs-bg-opacity: 1;
    background-color: #ffccc1 !important;
}
</style>