
<template>
  <div class="row gy-2">
    <div class="col-12">
      <div class="row mb-3 gy-2 justify-content-between">
        <div v-if="activeUser.id == doctorId" class="col-lg-12 d-flex">
          <button @click.prevent="popupAddModal = true" type="button" class="btn btn-primary" >
            <i class="ri ri-add-fill align-bottom"></i> Add Medicine </button>
        </div>
        <div class="col-lg-5">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultStatus" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by status --</option>
              <option v-for="(status, tIndex) in statusOptions"
                :value="status.value" :key="tIndex" > {{status.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <is-loading v-if="isLoading" />
      <div v-else>
        <div class="row">
          <div class="col-lg-6" v-for="(item, index) in pageData.data" :key="index">
            <prescribed-drug-card :item="item">
              <template v-slot:actions>
              </template>
            </prescribed-drug-card>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
    <b-modal size="lg" hide-footer v-model="popupAddModal" centered
       :title="editMode ? 'Update Medicine' : 'Add Medicine'" body-class="loading-viewport">
      <prescribed-drug-form v-if="popupAddModal" @closeMe="initializeAll()"
      :editMode="editMode" :editItem="editItem" @addItem="addItem($event)"
       @updatedItem="updateItem($event)" :medicalHistoryId="$route.params.historyId"/>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';
import PrescribedDrugForm from '@/components/forms/PrescribedDrugForm.vue'
import PrescribedDrugCard from '@/components/cards/PrescribedDrugCard.vue';

export default {
  name: "prescribed-drugs",
  components:{
    IsLoading,
    PrescribedDrugForm,
    PrescribedDrugCard
  },
  props:{
    doctorId:{
      type: Number,
      default: null
    },
    refresh: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      popupAddModal: false,
      editMode: false,
      editItem: null,
      defaultStatus: null,
      searchQuery: "",
      statusOptions:[
        { value: null , label: "All" },
        { value: 'pending' , label: "Pending" },
        { value: 'dispensed', label: "Dispensed" }
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      pageData: {
        data: []
      }
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = { page: page || 1, per_page: per_page || this.itemsPerPage,
                    medical_history_id: this.$route.params.historyId }
      if(this.defaultStatus){ payload.status = this.defaultStatus }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/prescribed-drugs?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
          }
        })
    },
    addItem(item){
      this.pageData.data.unshift(item);
      this.refresh()
    },
    updateItem(item){
      const dataIndex = this.pageData.data.findIndex((i) => i.id == item.id)
      Object.assign(this.pageData.data[dataIndex], item)
    },
    // initEdit(item){
    //   this.editItem = item,
    //   this.popupAddModal = true
    // },
    initAdd(){
      this.popupAddModal = true  
    },
    initializeAll(){
      this.editMode = false
      this.editItem = null
      this.popupAddModal = false
    }
  },
  mounted() {
    this.fetchItems()
    if(this.$route.query.add_drug){
      this.popupAddModal = true
    }
  },
}
</script>

