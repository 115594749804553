
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="item" class="col-12">
      <div class="d-sm-flex align-items-center justify-content-between">
        <button @click="$router.go(-1)" type="button" class="btn mb-3 btn-primary"> Back</button> 
        <h5 class="mb-3">Medical History Details</h5>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <div class="col-12">
                <div class=""><strong>Ref Number #: </strong> <span class="fw-bold fs-5">{{ item.id}}</span></div>
              </div>
            </div>
            <div v-if="activeUser.id == item.doctor_id" class="dropdown dropstart no-arrow">
              <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <ul class="dropdown-menu">
                <li>
                  <button @click.prevent="addAttribute('medicine')" class="dropdown-item"
                   type="button"> Add Medicine </button>
                </li>
                <li>
                  <button @click.prevent="popupModalShow = true" class="dropdown-item" 
                  type="button"> Edit Record </button>
                </li>
                <li>
                  <button @click.prevent="deleteItem()" class="dropdown-item" 
                  type="button"> Delete Record </button>
                </li>
                <li>
                  <button @click.prevent="downloadReceipt(item.id)" class="dropdown-item" 
                  type="button"> Print Record </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="pt-3">
            <div class="row g-2 align-items-center">
              <div class="col-md-6">
                <div class=""><strong>Weight: </strong> <span v-if="item.weight"> {{ item.weight }} kg </span></div>
              </div>
              <div class="col-md-6">
                <div class=""><strong>Height: </strong> <span v-if="item.height"> {{ item.height }} m </span></div>
              </div>
              <div class="col-md-6">
                <div class=""><strong>BMI: </strong> <span v-if="item.bmi"><span v-html="$filters.evaluateBMI(item.bmi)"></span> ( {{ item.bmi}} kg/m<sup>2</sup> ) </span></div>
              </div>
              <div class="col-md-6">
                <div class=""><strong>Blood Pressure:</strong> <span v-if="item.blood_pressure"> {{ item.blood_pressure}} mmHg</span></div>
              </div>
              <div class="col-md-6">
                <div class=""><strong>Pulse: </strong> <span v-if="item.pulse">{{ item.pulse}} bpm </span></div>
              </div>
              <div class="col-md-6">
                <div class=""><strong>No. of Drugs Prescribed #: </strong> {{ item.drugs_count }}</div>
              </div>
              <div class="col-12">
                <hr>
                <div class="mb-1"><strong>Ailments </strong></div>
                <ol class="">
                    <li v-for="(ailment, aiItem) in item.ailments" :key="aiItem"
                      class="mb-2">
                      <h6 class="mb-1">{{ ailment.title }}</h6>
                      <small>{{ ailment.description }}</small>
                    </li>
                </ol>
              </div>
              <div class="col-12">
                <hr>
                <div class="mb-1"><strong>Health description </strong></div>
                <div v-html="item.description"></div>
              </div>
              <div class="col-12">
                <div class=""><strong>Date created: </strong> {{ $filters.date(item.created_at, 1) }}</div>
              </div>
            </div>
            <div v-if="item.doctor" class="mt-2">
              <hr class="my-1">
              <div>
                <p class="mb-2"> Doctor's Details</p>
                <div class="d-flex">
                  <img :src="absoluteUrl(item.doctor.image)" class="rounded-circle me-3 avatar" />
                  <div>
                    <h3 class="card-title mb-1">
                      <span>
                          {{item.doctor.first_name}} {{item.doctor.last_name}}
                      </span>
                    </h3>
                    <p class="mb-1 small text-muted">{{item.doctor.mobile}} / {{ item.doctor.email }} </p>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class=""><strong>Hospital: </strong> 
              <span class="">
                  {{item.hospital.name}}
                  <span v-if="item.hospital.ward" class="mb-0">
                  {{item.hospital.ward.name}}
                  </span>
                  <span v-if="item.hospital.lga">,  
                  {{item.hospital.lga.name}} LGA
                  </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card bg-white overflow-hidden">
        <div class="card-header">
          <h6 class="mb-0">Patient's Details</h6>
        </div>
        <div class="card-body">
          <div class="d-md-flex">
            <div class="profile-user me-md-5 position-relative d-inline-block mb-4 mb-md-0">
              <img :src="absoluteUrl(item.patient.image)"
                class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
            </div>
            <div>
              <h5 class="fs-16 mb-1">
                {{item.patient.first_name}} {{item.patient.last_name}}
              </h5>
              <p class="mb-0"> {{ item.patient.friendly_id }}</p>
              <p class="mb-0"> Joined on {{$filters.date(item.patient.created_at)}}</p>
            </div>
          </div>
        </div>
        <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
      </div>
      <div class="pt-4">
        <h5 class="mb-3">Prescribed Drugs</h5>
        <prescribed-drugs :doctorId="item.doctor_id"
            ref="prescribedDrugs"
            :refresh="fetchItem"/> 
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal size="lg" v-model="popupModalShow" hide-footer
    title="Update medical history"
    body-class="loading-viewport" title-class="fs-6" @hide="initializeAll()">
    <medical-history-form 
      v-if="popupModalShow"
      :patientId="item.id" :edit-mode="true" :edit-item="item"
      @updateItem="updateItem($event)" @closeMe="initializeAll()"
    />
  </b-modal>
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import MedicalHistoryForm from '@/components/forms/MedicalHistoryForm.vue';
import PrescribedDrugs from './PrescribedDrugs.vue';

export default {
  components:{
    IsLoading,
    MedicalHistoryForm,
    PrescribedDrugs,
  },
  data(){
    return {
      isLoading: true,
      popupModalShow: false,
      item: null
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.item, item)
    },
    fetchItem(){
      this.$http.get(`/medical-histories/${this.$route.params.historyId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      this.confirmDelete({
        text: "You are about to delete this medical history",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/medical-histories/${this.item.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: `/patients/${this.item.patient_id}`})
            }
          })
        }
      });
    },
    downloadReceipt(itemId) {
      const payload = {
        url: '/generate-record',
        data: {  model_id: btoa(itemId), receipt: 'e6bb4808_1b46_45bd_8a5f_aadd2066a80e' }
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.serverReceipt(payload)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
              this.alertError('Could not generate receipt');
          }
      })
    }, 
    addAttribute(attribute){
      if(attribute == 'medicine'){
        this.$refs.prescribedDrugs.initAdd()
      }
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}
</script>

<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>